import React, { useContext, useState, useEffect } from "react";

// packages
import styled from "styled-components";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

//constest
import { Context } from "../../Contexts/Store";

//axios
import { dettConfig } from "../../../axiosConfig";

//componenets
import CalendarModal from "../../includes/modal/CalenderModal";
import DettLandingPageHeader from "../../includes/header/DettLandingPageHeader";
import ButtonLoader from "../../general/loader/ButtonLoader";

//functions
import { convertDate } from "../../general/utils/dateFunctions";

//custom hook
import { useFormatDate } from "../../general/hooks/useFormatDate";

export default function Register() {
    // states
    const [name, setName] = useState("");
    const [dob, setDob] = useState("");
    const [isShow, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [classDate, setClassDate] = useState(false);
    const [maxDate] = useState(new Date());
    const [dobErrMsg, setDobErrMsg] = useState("");
    const [nameErrMsg, setNameErrMsg] = useState("");
    const [firstLetter, setFirstLetter] = useState("");

    // hooks
    const navigate = useNavigate();
    const formatDate = useFormatDate();

    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);

    // states (store)
    const phone = user_data?.phone;
    const country = user_data?.country_code;
    const accessToken = user_data?.access_token;

    // navigation
    useEffect(() => {
        if (user_data?.registered) {
            navigate(-1);
        }
    }, []);

    // preventing numbers in name field
    const handleNameChange = (e) => {
        let value = e.target.value;
        const regex = /^[a-zA-Z ]*$/;
        if (regex.test(value)) {
            setName(value);
        }
    };

    //name validation
    const validateName = () => {
        var errors = "";
        if (name === "") {
            setNameErrMsg("This field is required");
            errors = "true";
        } else {
            setNameErrMsg("");
            errors = "false";
        }
        return errors;
    };

    // date validation
    const validateDate = () => {
        var errors = "";
        if (dob === "") {
            setDobErrMsg("This field is required");
            errors = "true";
        } else if (dob) {
            if (!moment(dob, "DD-MM-YYYY").isValid() || dob.length < 10) {
                setDobErrMsg("Enter a valid date");
                errors = "true";
            } else if (moment(dob).isAfter(new Date())) {
                setDobErrMsg("You can't enter future date");
                errors = "true";
            }
        } else {
            setDobErrMsg("");
            errors = "false";
        }
        return errors;
    };

    useEffect(() => {
        setDobErrMsg("");
    }, [dob]);

    //calendar handle function
    const handleCalendarlModal = () => {
        setShow(!isShow);
    };

    //setting firstletter of name
    useEffect(() => {
        const nameArray = name?.split(" ");
        let firstNameInitial = "";
        let lastNameInitial = "";

        if (nameArray?.length > 1) {
            firstNameInitial = nameArray[0][0];
            lastNameInitial = nameArray[1][0];
        } else {
            firstNameInitial = name[0];
        }

        setFirstLetter(`${firstNameInitial}${lastNameInitial}`);
    }, [name]);

    //signup api
    const handleSignup = async () => {
        setLoading(true);
        const dateError = validateDate();
        const NameError = validateName();
        dateError !== "true" &&
            NameError !== "true" &&
            (await dettConfig
                .post(
                    "api/v1/accounts/set/dob-and-name/",
                    {
                        name: name,
                        phone: phone,
                        country: country,
                        dob: convertDate(dob),
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    const { StatusCode } = response.data;
                    if (StatusCode === 6000) {
                        dispatch({
                            type: "UPDATE_ACCOUNT_DETAILS",
                            account_details: {
                                first_name: name,
                                last_name: "",
                                first_letters_of_name: firstLetter,
                            },
                        });
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                registered: true,
                            },
                        });
                        setLoading(false);
                        navigate("/pre-book", { replace: true });
                    } else if (StatusCode === 6001) {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                }));
        setLoading(false);
    };

    return (
        <>
            <DettLandingPageHeader />
            <MainContainer>
                <SubContainer className="wrapper">
                    <Container>
                        <Top>
                            <ImageBox>
                                <img
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/signup-bg.svg"
                                    }
                                    alt="signup"
                                />
                            </ImageBox>
                            <Heading>Complete your sign-up</Heading>
                            <Paragraph>Join our gifting community now.</Paragraph>
                        </Top>

                        <Bottom>
                            <SubHeading>Mobile Number</SubHeading>
                            <Items>
                                <NumberCode>{user_data?.phone_code}</NumberCode>
                                <PhNum>{phone}</PhNum>
                                <ImageContainer>
                                    <Tick
                                        src={
                                            "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/green-tick.png"
                                        }
                                        alt="tick"
                                    />
                                </ImageContainer>
                            </Items>

                            <FormPage>
                                <NameLabel>Name</NameLabel>
                                <InputName
                                    value={name}
                                    type="text"
                                    placeholder="Enter your name"
                                    onChange={(e) => {
                                        handleNameChange(e);
                                        setNameErrMsg("");
                                        setLoading(false);
                                    }}
                                    maxLength={50}
                                />

                                <DateLabel>
                                    Date Of Birth <Error className="first">{nameErrMsg}</Error>
                                </DateLabel>
                                <DateInputBox>
                                    <InputDate
                                        value={dob}
                                        type="text"
                                        placeholder="DD-MM-YYYY"
                                        onChange={(e) => {
                                            formatDate(e, setDob);
                                            setDobErrMsg("");
                                            setLoading(false);
                                        }}
                                        maxLength={10}
                                    />
                                    <CalendarImg
                                        onClick={() => {
                                            handleCalendarlModal();
                                        }}
                                    >
                                        <Calendar
                                            src={
                                                "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/calendar.png"
                                            }
                                            alt="calendar"
                                        />
                                    </CalendarImg>
                                </DateInputBox>

                                <CalendarModal
                                    isShow={isShow}
                                    setDate={(_date) => {
                                        setDob(moment(_date).format("DD-MM-YYYY"));
                                    }}
                                    date={dob}
                                    handleCalendarlModal={handleCalendarlModal}
                                    setShow={setShow}
                                    setClassDate={setClassDate}
                                    maxDate={maxDate}
                                />
                            </FormPage>
                            <ButtonDiv>
                                <Error>{dobErrMsg}</Error>
                                <SubmitButton onClick={() => handleSignup()}>
                                    {isLoading ? <ButtonLoader /> : "Continue"}
                                </SubmitButton>
                            </ButtonDiv>
                        </Bottom>
                    </Container>
                </SubContainer>
            </MainContainer>
        </>
    );
}

const MainContainer = styled.div`
    background: linear-gradient(180deg, #fff8f3 0%, #fff6ef 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding-top: 79px;
    @media all and (max-width: 1080px) {
        padding-top: 70px;
    }
    @media all and (max-width: 980px) {
        padding-top: 63px;
    }
    @media all and (max-width: 768px) {
        padding-top: 60px;
    }
    @media all and (max-width: 640px) {
        padding-top: 56px;
    }
    @media all and (max-width: 480px) {
        padding-top: 65px;
    }
`;

const SubContainer = styled.div`
    @media all and (max-width: 640px) {
        width: 90%;
    }
`;

const Container = styled.div`
    background: #fff;
    max-width: 500px;
    min-width: 320px;
    margin: 0 auto;
    text-align: center;
    border-radius: 12px;
    padding: 25px 60px;
    position: relative;
    overflow: hidden;
    @media all and (max-width: 480px) {
        padding: 20px;
    }
`;

const Top = styled.div`
    margin-bottom: 20px;
`;
const ImageBox = styled.div`
    width: 150px;
    margin: 0 auto;
    margin-bottom: 25px;
`;

const Heading = styled.h1`
    font-size: 18px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 5px;

    color: #282c3f;

    @media all and (max-width: 768px) {
        font-size: 16px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;

const Paragraph = styled.p`
    color: #777a86;
    font-size: 14px;
`;

const Bottom = styled.div`
    text-align: left;
`;

const SubHeading = styled.h2`
    font-size: 14px;
    color: #777a86;
    margin-bottom: 10px;
`;

const Items = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media all and (max-width: 768px) {
        margin-bottom: 10px;
    }
`;

const NumberCode = styled.h1`
    font-size: 14px;
    margin-right: 5px;
`;

const PhNum = styled.div`
    font-size: 14px;
    margin-right: 15px;
    color: #282c3f;

    @media all and (max-width: 1280px) {
        margin-right: 8px;
    }
`;

const ImageContainer = styled.div`
    width: 15px;

    @media all and (max-width: 1280px) {
        width: 13px;
    }
`;

const Tick = styled.img`
    width: 100%;
    display: block;
`;
const Error = styled.span`
    color: red;
    font-size: 16px;
    position: absolute;
    bottom: 45px;
    left: 2px;
    position: absolute;

    &.first {
        position: absolute;
        bottom: 20px;
        font-family: "dm_sansregular";
    }
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const FormPage = styled.div``;

const NameLabel = styled.h3`
    font-size: 14px;
    margin-bottom: 5px;
    font-family: "dm_sansboldmedium";
    color: #282c3f;
`;

const InputName = styled.input`
    border: 1px solid #e4e4e4;
    height: 42px;
    padding-left: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-family: "dm_sansregular";
    width: 100%;
    &::placeholder {
        color: #b9b9b9;
    }

    @media all and (max-width: 768px) {
        font-size: 12px;
    }
`;

const DateLabel = styled.h4`
    font-size: 14px;
    margin-bottom: 5px;
    font-family: "dm_sansboldmedium";
    color: #282c3f;

    position: relative;

    @media all and (max-width: 768px) {
        font-size: 12px;
    }
`;

const DateInputBox = styled.div`
    border: 1px solid #e4e4e4;
    height: 42px;
    padding-left: 15px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const InputDate = styled.input`
    font-family: "dm_sansregular";
    &::placeholder {
        color: #b9b9b9;
    }
    @media all and (max-width: 768px) {
        font-size: 12px;
    }
`;

const CalendarImg = styled.div`
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
`;

const Calendar = styled.img`
    width: 100%;
    display: block;
`;

const ButtonDiv = styled.div`
    position: relative;
`;

const SubmitButton = styled.div`
    text-align: center;
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    height: 45px;
    color: #fff;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    font-family: "dm_sansregular";
    display: flex;
    justify-content: center;
    align-items: center;
`;
