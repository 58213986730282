import React from "react";

//components
import AboutSpotlight from "./about/AboutSpotlight";
import CompanyDetails from "./about/CompanyDetails";
import Features from "./about/Features";
import Instagram from "./about/Instagram";
import Teams from "./about/Teams";
import HomeSurprise from "./home/HomeSurprise";
import ResellerReg from "./home/ResellerReg";
import HomeFooterpage from "./home/HomeFooterpage";
import DettLandingPageHeader from "../includes/header/DettLandingPageHeader";

//scroll hook
import { useScroll } from "../general/hooks/useScroll";

function About() {
    useScroll();
    return (
        <>
            <DettLandingPageHeader />
            <AboutSpotlight />
            <CompanyDetails />
            <Features />
            <HomeSurprise />
            <Teams />
            <Instagram />
            <ResellerReg />
            <HomeFooterpage />
        </>
    );
}

export default About;
