import React, { useContext, useRef, useState } from "react";

//packages
import ReCAPTCHA from "react-google-recaptcha";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

//axios
import { dettConfig } from "../../../axiosConfig";

//contest
import { Context } from "../../Contexts/Store";

//components
import ButtonLoader from "../../general/loader/ButtonLoader";
import DettLandingPageHeader from "../../includes/header/DettLandingPageHeader";
import CountryModal from "../../includes/modal/CountryModal";

//images
const ribbon =
    "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/ribbon.png";

export default function LoginPage() {
    //states
    const [dropDown, setDropDown] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    // store
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);

    const userData = user_data;

    // hooks
    const wrapperRef = useRef(null);
    const recaptchaRef = useRef(null);
    const navigate = useNavigate();

    //phone number validation
    const phoneNumberValidation = () => {
        var error = "";
        if (phoneNumber?.length === 0) {
            setErrorMsg("This field is required");
            error = "true";
        } else if (phoneNumber?.length !== userData?.phone_number_length) {
            setErrorMsg(`You must enter ${userData?.phone_number_length} digit number`);
            error = "true";
        } else {
            setErrorMsg("");
            error = "";
        }
        return error;
    };

    //enter press function call
    const onKeyPressHandler = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            phoneNumber && login();
        }
    };

    // login api
    const login = async () => {
        const error = phoneNumberValidation();
        setLoading(true);
        const token = await recaptchaRef.current.executeAsync();
        error !== "true" &&
            (await dettConfig
                .post("api/v1/accounts/join/", {
                    phone: phoneNumber,
                    country: userData?.country_code,
                    "g-recaptcha-response": token,
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setLoading(false);
                        dispatch({
                            type: "UPDATE_VERIFIED_STATE",
                            isVerified: "login_verified",
                        });
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                phone: phoneNumber,
                                is_exists: data.is_verified,
                            },
                        });
                        navigate("/otp", { replace: true });
                    } else if (StatusCode === 6001) {
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                }));
        setLoading(false);
    };

    // captcha resetting
    const onResetCaptcha = () => {
        recaptchaRef.current.reset();
    };

    return (
        <>
            <DettLandingPageHeader />
            <CountryModal
                dropDown={dropDown}
                setDropDown={setDropDown}
            />
            <MainContainer>
                <SubContainer className="wrapper">
                    <LoginBox>
                        <ImageContain>
                            <img
                                src={ribbon}
                                alt="img"
                            />
                        </ImageContain>
                        <TopContainer>
                            <ImageContainer>
                                <Image
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/login-bg.png"
                                    }
                                    alt="login"
                                />
                            </ImageContainer>
                        </TopContainer>

                        <Contents>
                            <Title>Wrap your love for your loved ones</Title>
                            <Description>
                                Sending you a little box of sunshine/happiness.
                            </Description>
                        </Contents>
                        <Bottom>
                            <MobNumber>Mobile Number</MobNumber>
                            <LoginSetting ref={wrapperRef}>
                                <LeftBox onClick={() => setDropDown(true)}>
                                    <CountryIcon>
                                        <Icon
                                            src={user_data?.flag}
                                            alt="flag"
                                        />
                                    </CountryIcon>

                                    <ArrowIcon>
                                        <Arrow src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/ArrowDown2.png" />
                                    </ArrowIcon>
                                </LeftBox>

                                <PhoneInput>
                                    {user_data?.phone_code} <span>|</span>
                                </PhoneInput>
                                <FormTag>
                                    <PhneNumber
                                        type="number"
                                        onKeyDown={(evt) => {
                                            evt.key === "e" && evt.preventDefault();
                                            isLoading && evt.preventDefault();
                                        }}
                                        onKeyPress={(e) => onKeyPressHandler(e)}
                                        id="phone"
                                        min="0"
                                        value={phoneNumber}
                                        onChange={(e) => {
                                            setPhoneNumber(e.target.value);
                                            setErrorMsg("");
                                            setLoading(false);
                                        }}
                                        placeholder="Enter your mobile number"
                                    />
                                </FormTag>

                                <Error>{errorMsg}</Error>
                            </LoginSetting>
                            <ButtonBox type={dropDown}>
                                <ContinueButton
                                    onClick={(e) => {
                                        login(e);
                                    }}
                                >
                                    {isLoading ? <ButtonLoader /> : "Continue"}
                                </ContinueButton>
                            </ButtonBox>
                            <Para>
                                By continuing, I agree to the{" "}
                                <SmallText
                                    target="_blank"
                                    href="/termsandcondition"
                                >
                                    Terms of Use{" "}
                                </SmallText>
                                &{" "}
                                <Small
                                    target="_blank"
                                    href="/privacypolicy"
                                >
                                    Privacy Policy
                                </Small>
                            </Para>
                        </Bottom>
                    </LoginBox>
                </SubContainer>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfzYwMiAAAAAIcd7i85zbUwcTayXgkvYEpLAQMt"
                    size="invisible"
                    badge="bottomleft"
                    onChange={onResetCaptcha}
                />
            </MainContainer>
        </>
    );
}

const MainContainer = styled.div`
    background: linear-gradient(180deg, #fff8f3 0%, #fff6ef 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding-top: 79px;
    @media all and (max-width: 1080px) {
        padding-top: 70px;
    }
    @media all and (max-width: 980px) {
        padding-top: 63px;
    }
    @media all and (max-width: 768px) {
        padding-top: 60px;
    }
    @media all and (max-width: 640px) {
        padding-top: 56px;
    }
    @media all and (max-width: 480px) {
        padding-top: 65px;
    }
`;

const SubContainer = styled.div`
    @media all and (max-width: 640px) {
        width: 90%;
    }
`;

const LoginBox = styled.div`
    background: #fff;
    max-width: 500px;
    min-width: 320px;
    margin: 0 auto;
    text-align: center;
    border-radius: 12px;
    padding: 60px;
    position: relative;
    overflow: hidden;
    @media all and (max-width: 480px) {
        padding: 20px;
    }
`;

const TopContainer = styled.div`
    margin-bottom: 30px;
`;

const ImageContainer = styled.div`
    width: 160px;
    margin: 0 auto;
`;

const Image = styled.img`
    width: 100%;
    display: block;
`;

const Contents = styled.div`
    margin-bottom: 30px;
`;

const Title = styled.h1`
    font-size: 18px;
    font-family: "dm_sansboldmedium";
    color: #282c3f;

    @media all and (max-width: 640px) {
        font-size: 16px;
    }

    @media all and (max-width: 480px) {
        margin-bottom: 10px;
    }
`;

const Description = styled.p`
    color: #777a86;
    font-size: 14px;
    margin-top: 5px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const LoginSetting = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cfcfcf;
    position: relative;
    border: 1px solid #e8e8e8;
    width: 100%;
    display: flex;
    font-size: 14px;
    align-items: center;
    height: 43px;
    border-radius: 5px;
    margin-bottom: 30px;
    position: relative;
    padding: 15px;
    &.phoneNumber {
        border: 0 solid #e8e8e8;
    }
`;

const LeftBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;
const Bottom = styled.div`
    text-align: left;
`;

const MobNumber = styled.h1`
    font-size: 14px;
    margin-bottom: 10px;
    font-family: "dm_sansregular";
    color: #282c3f;

    @media all and (max-width: 480px) {
        margin-left: 10px;
    }
`;

const CountryIcon = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    margin-right: 5px;
    border-radius: 50%;
    overflow: hidden;
    @media all and (max-width: 360px) {
        width: 20px;
        height: 20px;
    }
`;

const Icon = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;

const ArrowIcon = styled.div`
    width: 12px;
    margin-right: 10px;
    @media all and (max-width: 480px) {
        margin-right: 10px;
    }
    @media all and (max-width: 360px) {
        width: 12px;
    }
`;

const Arrow = styled.img`
    width: 100%;
    display: block;
`;

const PhoneInput = styled.h3`
    font-size: 14px;
    display: inline-block;
    display: flex;
    width: 45px;
    font-family: "dm_sansboldmedium";

    span {
        color: #9495a0;
        display: inline;
        margin: 0px 7px;
    }

    @media all and (max-width: 980px) {
        font-size: 14px;
    }

    @media all and (max-width: 640px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        margin-right: 3px;
    }
`;

const FormTag = styled.form`
    display: flex;
    align-items: center;
    appearance: none;
    width: 100%;
`;

const PhneNumber = styled.input`
    width: 100%;
    font-size: 14px;
    text-align: start;
    font-family: "dm_sansregular";
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    &::placeholder {
        color: #b9b9b9;
        @media all and (max-width: 380px) {
            font-size: 13px;
        }
    }
`;

const ButtonBox = styled.div`
    text-align: left;
    margin-top: 20px;
    width: ${({ type }) => (type ? "99%" : "100%")};
    margin: ${({ type }) => (type ? "0 auto" : "initial")};

    @media all and (max-width: 640px) {
        margin-top: 30px;
    }
`;
const Error = styled.span`
    color: #e01507;
    font-size: 16px;
    position: absolute;
    bottom: -23px;
    left: 2px;
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
const ImageContain = styled.div`
    width: 213px;
    position: absolute;
    left: -40px;
    top: -43px;
    img {
        width: 100%;
        display: block;
    }
    @media all and (max-width: 1400px) {
        width: 200px;
    }
    @media all and (max-width: 480px) {
        display: none;
    }
`;

const ContinueButton = styled.div`
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    height: 45px;
    color: #fff;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    font-family: "dm_sansboldmedium";
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Para = styled.p`
    color: #777a86;
    font-size: 14px;
    font-family: "dm_sansregular";
    margin-top: 20px;
    cursor: pointer;
    text-align: center;

    @media all and (max-width: 480px) {
        font-size: 12px;
        width: 85%;
        margin: 0 auto;
        margin-top: 12px;
    }

    @media all and (max-width: 360px) {
        margin: 15px auto 0px;
    }
`;

const SmallText = styled.a`
    color: #ff5e48;
    font-size: 14px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const Small = styled.a`
    color: #ff5e48;
    font-size: 14px;
    font-family: "dm_sansboldmedium";

    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;
