import axios from "axios";

// LOCAL SERVER CONFIGS

// export const dettConfig = axios.create({
//   baseURL: "http://127.0.0.1:8000/",
// });

//#### LIVE SERVER CONFIGS #### //
export const dettConfig = axios.create({
    baseURL: "https://api-pre-booking.dett.app/",
});

// #### DEVELOPER SERVER CONFIGS #### //
// export const dettConfig = axios.create({
//     baseURL: "https://developers-dett-prebooking.talrop.works/",
// });
