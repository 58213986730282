import React, { useState, useEffect, useContext, useRef } from "react";

//packages
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

//contest
import { Context } from "../../Contexts/Store";

//axios
import { dettConfig } from "../../../axiosConfig";

//components
import ButtonLoader from "../../general/loader/ButtonLoader";
import DettLandingPageHeader from "../../includes/header/DettLandingPageHeader";

export default function OtpPage() {
    // states
    const [otpInputValue, setOtpInputValue] = useState("");
    const [otpTimer, setOtpTimer] = useState(15);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState("Success!");

    const navigate = useNavigate();
    const recaptchaRef = useRef(null);
    const otpRef = useRef(null);

    // state (store)
    const {
        dispatch,
        state: { user_data, isVerified },
    } = useContext(Context);

    // navigation
    useEffect(() => {
        if (isVerified !== "login_verified") {
            navigate("/login");
        }
    }, []);

    // store data(contest)
    const phone = user_data?.phone;
    const country = user_data?.country_code;
    const isUser = user_data?.is_exists;

    //otp input value
    const handleChange = (value) => {
        setOtpInputValue(value);
    };

    //enter press function call
    const onKeyPressHandler = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            otpInputValue && verifyOtp();
        }
    };

    // validation of otp
    const validateOTP = () => {
        var errors = "";
        if (!otpInputValue) {
            setError(true);
            setErrorMsg("This field is required");
            errors = "true";
        } else if (otpInputValue.length !== 4) {
            setError(true);
            setErrorMsg("Enter a valid OTP");
            errors = "true";
        } else {
            setError(false);
            setErrorMsg("");
            errors = "false";
        }
        return errors;
    };

    // error message timeout function
    useEffect(() => {
        setTimeout(() => {
            setError(false);
        }, 5000);
    }, [error]);

    // verify otp api
    const verifyOtp = async () => {
        setLoading(true);
        const errors = validateOTP();
        otpInputValue &&
            errors !== "true" &&
            (await dettConfig
                .post("api/v1/accounts/verify/otp/", {
                    otp: otpInputValue,
                    phone: phone,
                    country: country,
                })
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        setLoading(false);
                        dispatch({
                            type: "UPDATE_VERIFIED_STATE",
                            isVerified: "otp_verified",
                        });

                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: {
                                access_token: data?.access_token,
                                is_exists: data?.is_exists,
                                logged_in: true,
                            },
                        });
                        if (isUser) {
                            navigate("/pre-book");
                        } else {
                            navigate("/register", { replace: true });
                        }
                    } else {
                        if (StatusCode === 6001) {
                            setLoading(false);
                            setError(true);
                            setErrorMsg("Enter a valid OTP");
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setError(true);
                }));
        setLoading(false);
    };

    // resend otp api
    const rensendOtp = async () => {
        const token = await recaptchaRef.current.executeAsync();
        dettConfig
            .post("api/v1/accounts/resend/otp/", {
                phone: phone,
                country: country,
                "g-recaptcha-response": token,
            })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setError(false);
                    setOtpTimer(15);
                } else {
                    if (data.title === "Failed!") {
                        setTitle(data.title);
                        setOtpTimer(600);
                    } else {
                        setOtpTimer(15);
                        setTitle("");
                    }
                    setErrorMsg(data.message);
                    setError(true);
                }
            })
            .catch((error) => {
                setError(true);
            });
    };

    // otp timer function
    useEffect(() => {
        if (otpTimer > 0) {
            setTimeout(() => setOtpTimer(otpTimer - 1), 1000);
        }
    }, [otpTimer]);

    // formatting time into minutes and seconds
    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
        return `${minutes}:${formattedSeconds}`;
    }

    // captcha resetting
    const onResetCaptcha = () => {
        recaptchaRef.current.reset();
    };

    return (
        <>
            <DettLandingPageHeader />
            <MainContainer>
                <SubContainer className="wrapper">
                    <ContentBox>
                        <TopBox>
                            <ImgContainer>
                                <Img
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/20-03-2023/otp-bg.png"
                                    }
                                    alt="otp-img"
                                />
                            </ImgContainer>
                        </TopBox>

                        <BottomBox>
                            <MainHeading>Verify OTP</MainHeading>
                            <Paragraph>Verify the OTP to start gifting easily.</Paragraph>

                            <OtpTag>
                                <OtpInput
                                    ref={otpRef}
                                    value={otpInputValue}
                                    onChange={(value) => {
                                        setLoading(false);
                                        handleChange(value);
                                        setError(false);
                                    }}
                                    numInputs={4}
                                    placeholder={""}
                                    isInputNum={true}
                                    inputStyle={"OtpInput"}
                                    shouldAutoFocus={true}
                                    focusStyle={"focused-field"}
                                    onKeyPressHandler={onKeyPressHandler}
                                />
                                {error && (
                                    <ErrorMsg className={title === "Failed!" ? "active" : ""}>
                                        {errorMsg}
                                    </ErrorMsg>
                                )}
                            </OtpTag>

                            <OtpResend className={title === "Failed!" ? "active" : ""}>
                                {otpTimer <= 0 ? (
                                    <ResendButton
                                        onClick={() => {
                                            rensendOtp();
                                        }}
                                    >
                                        Resend OTP
                                    </ResendButton>
                                ) : (
                                    <OtpTimer>
                                        Resend in{" "}
                                        <Span>
                                            {title === "Failed!" ? formatTime(otpTimer) : otpTimer}{" "}
                                            Sec
                                        </Span>
                                    </OtpTimer>
                                )}
                            </OtpResend>

                            <ButtonContainer>
                                <Button
                                    type={otpTimer}
                                    onClick={() => {
                                        verifyOtp();
                                    }}
                                >
                                    {loading ? <ButtonLoader /> : "Continue"}
                                </Button>
                            </ButtonContainer>
                        </BottomBox>
                    </ContentBox>
                </SubContainer>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfzYwMiAAAAAIcd7i85zbUwcTayXgkvYEpLAQMt"
                    size="invisible"
                    badge="bottomleft"
                    onChange={onResetCaptcha}
                />
            </MainContainer>
        </>
    );
}

const MainContainer = styled.div`
    background: linear-gradient(180deg, #fff8f3 0%, #fff6ef 100%);
    display: flex;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding-top: 79px;
    @media all and (max-width: 1080px) {
        padding-top: 70px;
    }
    @media all and (max-width: 980px) {
        padding-top: 63px;
    }
    @media all and (max-width: 768px) {
        padding-top: 60px;
    }
    @media all and (max-width: 640px) {
        padding-top: 56px;
    }
    @media all and (max-width: 480px) {
        padding-top: 65px;
    }
`;

const SubContainer = styled.div`
    @media all and (max-width: 640px) {
        width: 90%;
    }
`;

const ContentBox = styled.div`
    background: #fff;
    max-width: 500px;
    min-width: 320px;
    margin: 0 auto;
    text-align: center;
    border-radius: 12px;
    padding: 45px 60px;
    position: relative;
    overflow: hidden;
    @media all and (max-width: 480px) {
        padding: 20px;
    }
`;

const TopBox = styled.div`
    margin-bottom: 30px;
`;

const ImgContainer = styled.div`
    width: 160px;
    margin: 0 auto;
`;

const Img = styled.img`
    width: 100%;
    display: block;
`;

const BottomBox = styled.div`
    overflow: hidden;
`;

const MainHeading = styled.h1`
    font-size: 18px;
    font-family: "dm_sansboldbold";
    color: #282c3f;
`;

const Paragraph = styled.p`
    color: #777a86;
    font-size: 14px;
    margin-bottom: 30px;
    font-family: "dm_sansregular";
`;

const OtpTag = styled.div`
    margin-bottom: 10px;
    position: relative;
    //OTP FIELD CUSTOM STYLES
    div {
        display: flex !important;
        justify-content: space-between;
        gap: 20px;
        @media all and (max-width: 540px) {
            gap: 12px;
        }
        @media all and (max-width: 480px) {
            gap: 10px;
        }
        @media all and (max-width: 380px) {
            gap: 7px;
        }
    }
    .focused-field {
        border-color: #ff5e48 !important;
    }
    //OTP FIELD CUSTOM STYLES
    .OtpInput {
        width: 75px !important;
        height: 55px !important;
        font-size: 24px;
        font-family: "dm_sansregular";
        color: #282c3f;
        border: 2px solid #e4e4e4;
        border-radius: 8px;
        margin-bottom: 10px;
        @media all and (max-width: 540px) {
            width: 70px !important;
            height: 55px !important;
        }

        @media all and (max-width: 400px) {
            width: 60px !important;
            height: 50px !important;
        }

        @media all and (max-width: 980px) {
            font-size: 18px;
        }

        @media all and (max-width: 360px) {
            margin-right: 5px;
            font-size: 16px;
        }
    }
`;

const ErrorMsg = styled.p`
    font-size: 16px;
    color: #e01507;
    text-align: left;
    margin-bottom: 5px;
    position: absolute;
    word-wrap: break-word;
    bottom: -20px;
    &.active {
        bottom: -35px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`;

const OtpResend = styled.div`
    margin-bottom: 20px;
    margin-top: 15px;
    &.active {
        margin-top: 25px;
    }
`;

const ResendButton = styled.h3`
    font-family: "dm_sansboldmedium";
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    color: #e01507;
    width: max-content;

    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const OtpTimer = styled.h2`
    font-size: 16px;
    font-family: "dm_sansregular";
    text-align: left;
    color: #777a86;
    margin-top: 5px;
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const Span = styled.span`
    font-size: 16px;
    font-family: "dm_sansregular";
    display: inline-block;
    color: #282c3f;
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const ButtonContainer = styled.div``;

const Button = styled.div`
    background: linear-gradient(180deg, #ff7d6c 0%, #ff5740 100%);
    height: 45px;
    color: #fff;
    border-radius: 6px;
    font-size: 14px;
    font-family: "dm_sansregular";
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
