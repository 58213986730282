import React from "react";

//packages
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Fade } from "react-reveal";
import { HashLink } from "react-router-hash-link";

//components
import ReusableButton from "../../general/ReusableButton";

function AboutSpotlight() {
    //slider settings
    const settings = {
        infinite: true,
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
    };

    return (
        <MainContainer
            id="about-spotlight"
            className="wrapper"
        >
            <LeftContainer>
                <Fade
                    bottom
                    cascade
                >
                    <SmallText>We Gift You Happiness !</SmallText>
                    <Title>
                        Re-Define Your
                        <Small> Gifting</Small> Experience
                    </Title>
                    <Sentence>
                        Gift is a reflection of both the giver and the receiver, as well as their
                        unique relationship. It allows us to communicate our feelings of love,
                        friendship, bonding and appreciation for them.
                    </Sentence>
                    <Button to="/#formpage">
                        <ReusableButton title={"Contact Us"} />
                    </Button>
                </Fade>
            </LeftContainer>
            <RightContainer>
                <BackContainer>
                    <MainBackgroundImage
                        src={
                            "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/new-outline.png"
                        }
                        alt="outline"
                    />
                </BackContainer>
                <ContainerSlider>
                    <DivColourRight></DivColourRight>
                    <SliderContainer>
                        <ImageContainer>
                            <Slider {...settings}>
                                <BackgroundImage
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/slider-one.png"
                                    }
                                    alt="one"
                                />
                                <BackgroundImage
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/slider-two.png"
                                    }
                                    alt="two"
                                />
                                <BackgroundImage
                                    src={
                                        "https://dett-react-assets-bucket.s3.ap-south-1.amazonaws.com/dett-assets/11-01-2023/slider-three.png"
                                    }
                                    alt="three"
                                />
                            </Slider>
                        </ImageContainer>
                    </SliderContainer>
                    <DivColourLeft></DivColourLeft>
                </ContainerSlider>
            </RightContainer>
        </MainContainer>
    );
}

const ContainerSlider = styled.div`
    display: flex;
    background: linear-gradient(90deg, rgba(245, 248, 248, 0.01) 0%, #ffffff 100%);
`;

const DivColourRight = styled.div`
    width: 108px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
    background: linear-gradient(90deg, rgba(245, 248, 248, 0.01) 0%, rgb(255, 255, 255) 100%);
    @media all and (max-width: 768px) {
        width: 58px;
    }
    @media all and (max-width: 480px) {
        width: 39px;
    }
`;
const DivColourLeft = styled.div`
    width: 44px;
    height: 92%;
    position: absolute;
    bottom: 0;
    left: 0px;
    background: linear-gradient(90deg, #ffffff5c 0%, #cccccc00 100%);
`;

const SliderContainer = styled.div`
    width: 84%;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Button = styled(HashLink)`
    width: max-content;
    display: inline-block;
    @media all and (max-width: 980px) {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: auto;
    }
`;

const MainContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 90vh; */
    /* padding: 28% 0 24% 0; */
    //new
    /* padding: 24% 0 24% 0; */
    /* padding: 140px 0; */
    margin-top: 200px;
    padding-bottom: 140px;
    @media all and (max-width: 1280px) {
        /* padding: 27% 0 20% 0; */
        margin-top: 180px;
        padding-bottom: 115px;
    }
    @media all and (max-width: 1080px) {
        /* padding: 32% 0 20% 0; */
        margin-top: 170px;
        padding-bottom: 100px;
    }
    @media all and (max-width: 980px) {
        /* padding: 20% 0 0 0; */
        margin-top: 150px;
        padding-bottom: 0px;
    }

    @media all and (max-width: 980px) {
        flex-wrap: wrap-reverse;
        height: unset;
    }

    @media all and (max-width: 640px) {
        margin-top: 120px;
    }
    .wrapper {
        @media all and (max-width: 480px) {
            width: 90%;
        }
    }
`;
const LeftContainer = styled.div`
    width: 45%;
    @media all and (max-width: 1080px) {
        /* width: 48%; */
        margin: 0 auto;
    }
    @media all and (max-width: 980px) {
        width: 90%;
        /* margin: 0 auto; */
        margin-top: 50px;
    }
`;
const RightContainer = styled.div`
    width: 50%;
    position: relative;

    @media all and (max-width: 980px) {
        width: 85%;
        margin-top: 40px;
        margin: 0 auto;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        /* margin-top: 60px; */
    }
`;
const SmallText = styled.h4`
    color: #282c3f;
    font-size: 13px;
    font-family: "dm_sansboldmedium";
    margin-bottom: 10px;
    @media all and (max-width: 481px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
    @media all and (max-width: 980px) {
        font-size: 14px;
        text-align: center;
    }
`;
const Title = styled.h1`
    color: #282c3f;
    font-size: 50px;
    font-family: "dm_sansboldbold";
    margin-bottom: 10px;
    @media all and (max-width: 1280px) {
        font-size: 42px;
        width: 100%;
    }
    @media all and (max-width: 1200px) {
        font-size: 38px;
    }
    @media all and (max-width: 1080px) {
    }
    @media all and (max-width: 980px) {
        text-align: center;
        width: unset;
    }
    @media all and (max-width: 640px) {
        font-size: 30px;
    }
    @media all and (max-width: 480px) {
        font-size: 25px;
        text-align: center;
    }
    @media all and (max-width: 360px) {
        font-size: 23px;
    }
`;
const Small = styled.span`
    font-family: "dm_sansboldbold";
    background: -webkit-linear-gradient(#ff7663 0%, #cd1900 144.28%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
`;
const Sentence = styled.p`
    color: #777a86;
    width: 95%;
    margin-bottom: 45px;
    font-family: "dm_sansboldmedium";
    line-height: 23px;
    font-size: 15px;
    line-height: 20px;
    @media all and (max-width: 1080px) {
        width: 100%;
    }
    @media all and (max-width: 481px) {
        font-size: 18px;
        width: 100%;
    }
    @media all and (max-width: 980px) {
        font-size: 16px;
        text-align: center;
    }
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
`;

const BackContainer = styled.div`
    position: absolute;
    top: 10px;
    /* right: 75px; */
    //new
    right: 14%;
    width: 85%;

    @media all and (max-width: 1080px) {
        position: absolute;
        /* top: -35px; */
        /* right: 0; */
        /* width: 100%; */
    }

    @media all and (max-width: 980px) {
        position: absolute;
        /* top: -13px; */
        /* right: 104px; */
        //new
        right: 16%;
        width: 76%;
    }
    @media all and (max-width: 768px) {
        /* right: 46px; */
        width: 92%;
        right: 7%;
    }

    @media all and (max-width: 480px) {
        /* right: 37px; */
        width: 85%;
        right: 5%;
    }
`;
const ImageContainer = styled.div`
    margin: 0 auto;
    @media all and (max-width: 1280px) {
        width: 100%;
    }
    @media all and (max-width: 1080px) {
        width: 100%;
    }
    @media all and (max-width: 980px) {
        width: 85%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        width: 85%;
    }
`;
const BackgroundImage = styled.img`
    width: 100%;
    display: block;
    margin-left: 10px !important;

    @media all and (max-width: 480px) {
        border-radius: 30px;
    }
`;

const MainBackgroundImage = styled.img`
    width: 100%;
    height: 90%;
    display: block;
    @media all and (max-width: 1080px) {
        height: 85%;
    }
`;

export default AboutSpotlight;
