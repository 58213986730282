import React from "react";

//apckages
import { Navigate, Outlet } from "react-router-dom";

//auth function
import { isAuth } from "../../general/utils/isAuth";

export const ProtectedRouteAfterLogin = () => {
    const auth = isAuth();
    return auth ? <Navigate to={-1} /> : <Outlet />;
};
