import React from "react";

//packages
import { Navigate, Outlet } from "react-router-dom";

//Authentication hook
import { isAuth } from "../../general/utils/isAuth";

function PrivateRoute() {
    const auth = isAuth();

    return auth ? <Outlet /> : <Navigate to={-1} />;
}

export default PrivateRoute;
