export const useFormatDate = () => {
    const formatDate = (e, setState) => {
        let value = e.target.value;
        var numbers = /^[0-9!@#$%^&*()-?":{}|<>{/}]+$/;
        if (value.match(numbers)) {
            let typedDate =
                value.length === 3 && !value.includes("-")
                    ? `${value.substring(0, 2)}-${value.substring(2)}`
                    : value.length === 6 && value[value.length - 1] !== "-"
                    ? `${value.substring(0, 5)}-${value.substring(5)}`
                    : value;
            setState(typedDate);
        } else {
            setState("");
            return false;
        }
    };
    return formatDate;
};
