import React, { useContext, useEffect, useRef, useState } from "react";

//packages
import styled from "styled-components";

//axios
import { dettConfig } from "../../../axiosConfig";

//contest
import { Context } from "../../Contexts/Store";

function CountryModal({ dropDown, setDropDown }) {
    const {
        dispatch,
        state: { user_data },
    } = useContext(Context);

    const countryBoxRef = useRef(null);
    const userData = user_data;
    //country modal states
    const [phoneNumberLength, setPhoneNumberLength] = useState(10);
    const [phoneCode, setPhoneCode] = useState("+91");
    const [countryCode, setCountryCode] = useState("IN");
    const [searchCountry, setSearchCountry] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [flag, setFlag] = useState(
        "https://developers-dett-prebooking.talrop.works/media/countries/flags/India.png"
    );
    const [highlightedIndex, setHighlightedIndex] = useState(0);

    //while going back we need to preserve phonecode,phone,flag,countrycode,
    useEffect(() => {
        userData?.phone_code ? setPhoneCode(userData?.phone_code) : setPhoneCode("+91");
        userData?.flag
            ? setFlag(userData.flag)
            : setFlag(
                  "https://developers-dett-prebooking.talrop.works/media/countries/flags/India.png"
              );
        userData?.country_code ? setCountryCode(userData?.country_code) : setCountryCode("IN");
        userData?.phone_number_length
            ? setPhoneNumberLength(userData?.phone_number_length)
            : setPhoneNumberLength(10);
    }, []);

    useEffect(() => {
        dispatch({
            type: "UPDATE_USER_DATA",
            user_data: {
                phone_code: phoneCode,
                phone_number_length: phoneNumberLength,
                country_code: countryCode,
                flag: flag,
            },
        });
    }, [countryCode, phoneCode, phoneNumberLength, flag]);

    useEffect(() => {
        dettConfig
            .get("api/v1/general/countries/")
            .then((response) => {
                const { data } = response.data.data;
                setCountryList(data);
            })
            .catch((error) => {});
    }, []);

    //autofocusing country modal
    useEffect(() => {
        if (dropDown) {
            countryBoxRef.current.focus();
        }
    }, [dropDown]);

    //country search field validation
    const handleCountryChange = (e) => {
        setHighlightedIndex(0);
        let value = e.target.value.trimStart();
        const regEx = /^[A-Za-z]*$/;
        if (regEx.test(value)) {
            setSearchCountry(value);
        }
    };

    //country sellection (click function)
    const selectCountry = (country) => {
        setFlag(country.flag);
        setPhoneCode(country.phone_code);
        setPhoneNumberLength(country.phone_number_length);
        setCountryCode(country.web_code);
        setSearchCountry("");
        setDropDown(false);
    };

    //country sellection (keydown function)
    const handleKeyDown = (event) => {
        if (event.key === "ArrowUp" && highlightedIndex > 0) {
            // Move highlight up when up arrow is pressed
            setHighlightedIndex((prevIndex) => prevIndex - 1);
        } else if (event.key === "ArrowDown" && highlightedIndex < countryList.length - 1) {
            // Move highlight down when down arrow is pressed
            setHighlightedIndex((prevIndex) => prevIndex + 1);
        } else if (event.key === "Enter") {
            setFlag(countryList[highlightedIndex].flag);
            setPhoneCode(countryList[highlightedIndex].phone_code);
            setPhoneNumberLength(countryList[highlightedIndex].phone_number_length);
            setCountryCode(countryList[highlightedIndex].web_code);
            setSearchCountry("");
            setDropDown(false);
        }
    };

    return (
        <Overlay
            type={dropDown}
            onClick={() => {
                setDropDown(false);
            }}
        >
            <DropDown
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <SearchBox>
                    <SearchInput
                        type="search"
                        placeholder="Search Country"
                        onChange={handleCountryChange}
                        value={searchCountry}
                    />
                </SearchBox>
                <CountryBox
                    id="countrybox"
                    onKeyDown={handleKeyDown}
                    tabIndex={10}
                    ref={countryBoxRef}
                >
                    {dropDown && (
                        <CountryInfoBox>
                            {countryList
                                .filter((item) =>
                                    item.name.toLowerCase().includes(searchCountry.toLowerCase())
                                )
                                .map((item, index) => (
                                    <CountryDetails
                                        key={index}
                                        onClick={() => {
                                            selectCountry(item);
                                        }}
                                        className={highlightedIndex === index ? "highlighted" : ""}
                                    >
                                        <FlagBox>
                                            <Flag src={item.flag} />
                                        </FlagBox>
                                        <CountryName>{item.name}</CountryName>
                                        <PhoneCode>{item.phone_code}</PhoneCode>
                                    </CountryDetails>
                                ))}
                        </CountryInfoBox>
                    )}
                </CountryBox>
            </DropDown>
        </Overlay>
    );
}

export default CountryModal;

const Overlay = styled.section`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: fixed;
    inset: 0px;
    background: rgba(0, 0, 0, 0.5);
    opacity: ${({ type }) => (type ? "1" : "0")};
    z-index: ${({ type }) => (type ? "1000" : "-1")};
`;

const DropDown = styled.div`
    padding: 18px 15px;
    width: 100%;
    transition: 0.5s ease;
    background: #fffcf7;
    border: 1px solid #e8e8e8;
    position: fixed;
    max-width: 320px;
    min-width: 320px;
    max-height: 450px;
    min-height: 450px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 480px) {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 286px;
        min-height: 286px;
    }
`;

const SearchBox = styled.form`
    margin-bottom: 5px;
    border: 2px solid #f2efe9;
    padding: 10px;
    width: 100%;

    @media all and (max-width: 1280px) {
        background-size: 15px;
    }

    @media all and (max-width: 768px) {
        background-size: 12px;
        background-position: center left 7px;
    }

    @media all and (max-width: 640px) {
        background-size: 14px;
        background-position: center left 25px;
    }
`;

const SearchInput = styled.input`
    font-size: 14px;
    color: #000;
    font-family: "dm_sansregular";
    background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/dett/10-11-2022/Search.svg")
        no-repeat;
    background-size: 16px;
    border-radius: 8px;
    background-position: center left 0px;
    width: 100%;
    padding-left: 25px;

    @media all and (max-width: 1160px) {
        font-size: 12px;
    }

    @media all and (max-width: 980px) {
        font-size: 12px;
    }
`;

const CountryBox = styled.div`
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    overflow-y: scroll !important;
    scrollbar-width: thin !important;
    scrollbar-color: #bbb #eee !important;
    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar {
        display: block !important;
    }

    ::-webkit-scrollbar-thumb {
        background: #747474;
    }
`;

const CountryInfoBox = styled.div``;

const CountryDetails = styled.li`
    display: flex;
    :hover {
        background: #fff7ea;
    }
    &.highlighted {
        background: #fff7ea !important;
    }
    padding: 10px;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
`;

const FlagBox = styled.div`
    width: 32px;

    @media all and (max-width: 1160px) {
        width: 28px;
    }

    @media all and (max-width: 980px) {
        margin-right: 5px;
    }
`;

const Flag = styled.img`
    display: block;
    width: 100%;
    height: 100%;
`;

const CountryName = styled.h2`
    font-size: 17px;
    font-family: "dm_sansregular";
    width: 120px;
    text-align: left;

    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;

const PhoneCode = styled.h5`
    font-size: 17px;
    font-family: "dm_sansregular";
    width: 45px;
    text-align: left;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
