import React from "react";
import { useEffect } from "react";

export const useScroll = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);
    return <div></div>;
};
